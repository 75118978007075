<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-import">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Sellos por Excel</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-12">
              <label for="">Sitio</label>
              <v-select
                v-model="sitio"
                placeholder="Sitio"
                label="nombre"
                :options="listasForms.sitios"
                class="form-control form-control-sm p-0"
                :class="[
                  $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                @search="buscarSitio"
                @input="selectSitio"
              ></v-select>
            </div>
            <div
              class="btn image-preview-input col-md-12"
              :class="
                form.file
                  ? 'alert-default-success border border-success'
                  : 'alert-default-warning border border-warning'
              "
            >
              <input
                type="file"
                id="file"
                ref="file"
                class="form-control btn btn-light"
                v-on:change="elegirDirectorio()"
                accept=".XLSX, .CSV"
                :class="$v.form.file.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
          </div>

          <div class="modal-footer">
            <div class="row">
              <button
                type="button"
                class="bagde bg-gradient-primary btn-sm border-primary"
                @click="importExcel()"
                v-show="!$v.form.$invalid"
              >
                Importar
                <i class="fas fa-cloud-upload-alt"></i>
              </button>
              <button
                type="button"
                class="bagde bg-gradient-danger btn-sm border-danger"
                @click="limpiarArchivo()"
              >
                Limpiar
                <i class="fas fa-trash-alt"></i>
              </button>
              <button
                type="button"
                class="bagde bg-gradient-dark btn-sm border-dark"
                @click="exportExcel()"
                v-show="$v.form.$invalid"
              >
                Plantilla
                <i class="fas fa-cloud-download-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "SelloImportExcel",
  components: {
    vSelect,
  },
  data() {
    return {
      form: { sitio_id: null, file: null },
      sitio: [],
      listasForms: {
        sitios: [],
      },
    };
  },
  validations: {
    form: {
      sitio_id: {
        required,
      },
      file: {
        required,
      },
    },
  },
  methods: {
    elegirDirectorio() {
      this.form.file = this.$refs.file.files[0];
    },

    buscarSitio(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.sitios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitio() {
      this.form.sitio_id = null;
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    importExcel() {
      this.$parent.cargando = true;
      let formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("sitio_id", this.form.sitio_id);
      axios
        .post("/api/hidrocarburos/sellos/importExcel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$refs.closeModal.click();
          this.$parent.getIndex();
          const data = response.data;

          let html = "";
          if (data.msg_error) {
            html = `<strong>Errores en el archivo</strong>`;
            data.msg_error.forEach((er) => {
              html += `<div>${er}</div>`;
            });
          }
          this.$swal({
            title: `Se actualizarón ${data.cuantos_creados} de los ${data.arraySellosCount} registros del archivo subido.`,
            html: html,
            icon: "info",
            showCloseButton: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title:
              "Ocurrio un error, por favor validar que los sellos no se encuentren duplicados...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    exportExcel() {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/sellos/exportExcel",
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    limpiarArchivo() {
      this.sitio = [];
      this.form.file = null;
      this.form.sitio_id = null;
      document.getElementById("file").value = "";
    },
  },
};
</script>
